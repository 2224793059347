export const listVideotimestampsByAuthor = `query ListVideotimestampsByAuthor(
  $username: String!
  $count: Int
  $nextToken: String
) {
  listVideotimestampsByAuthor(
    username: $username
    count: $count
    nextToken: $nextToken
  ) {
    items {
      id
      username
      videourl
      title
      description
      tags
      playlist
    }
    nextToken
  }
}
`;

export const listVideotimestamps = `query ListVideotimestamps(
  $filter: ModelVideotimestampFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideotimestamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      videourl
      title
      description
      tags
      playlist
    }
    nextToken
  }
}
`;

export const createVideotimestamp = `mutation CreateVideotimestamp($username: String!, $videourl: String!, $title: String!, $description: String!, $tags: String) {
  createVideotimestamp(input:{
    username:$username
    videourl:$videourl
    title:$title
    description:$description
    tags:$tags
  })  {
    id
    username
    videourl
    title
    description
    tags
    playlist
  }
}
`;

export const updateVideotimestamp = `mutation UpdateVideotimestamp($id: ID!, $username: String!, $videourl: String!, $title: String!, $description: String!, $tags: String) {
  updateVideotimestamp(input:{
    id:$id
    username:$username
    videourl:$videourl
    title:$title
    description:$description
    tags:$tags
  })  {
    id
    username
    videourl
    title
    description
    tags
    playlist
  }
}
`;

export const deleteVideotimestamp = `mutation DeleteVideotimestamp($id: ID!) {
  deleteVideotimestamp(input:{
    id: $id
  }) {
    id
    username
    videourl
    title
    description
    tags
    playlist
  }
}
`;

export const onCreateOrUpdateOrDeleteVidz = `subscription onCreateOrUpdateOrDeleteVidz {
  onCreateVideotimestamp {
    id
    username
    videourl
    title
    description
    tags
    playlist
  },
  onUpdateVideotimestamp {
    id
    username
    videourl
    title
    description
    tags
    playlist
  }
  onDeleteVideotimestamp {
    id
    username
    videourl
    title
    description
    tags
    playlist
  }
}
`;

export const searchVideotimestamps = `query searchVideotimestamps($search: String){
  searchVideotimestamps(filter:{description:{match:$search}}){
    items{
      id
      username
      videourl
      title
      tags
      description
      playlist
    }
  }
}`