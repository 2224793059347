import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter,
} from "react-router-dom";

import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import { ConfirmSignIn, ConfirmSignUp, Greetings, ForgotPassword, RequireNewPassword, SignUp, VerifyContact} from 'aws-amplify-react';
import CustomLogin from './CustomLogIn'

//import 'bulma/css/bulma.css'
//import 'bulma-helpers/css/bulma-helpers.min.css'
//import '../css/custom.css'

//components
import MyStamps from '../components/Profile/MyStamps'
import Profile from '../components/Profile/Profile'

//icons
import {FaSearch, FaUserCircle} from 'react-icons/fa';

//amplify stuff
import Amplify from '@aws-amplify/core'

import awsconfig from '../config'

Amplify.configure(awsconfig)


class Main extends Component {

  render() {
    if (this.props.authState === "signedIn"){
      return (
        <HashRouter>

        <section className="section">
          <div className="container">
            <div className="tabs is-centered is-toggle is-toggle-rounded">
              <ul>
                <li>
                    <NavLink to="/mystamps"><span className="icon is-small"><FaSearch /></span></NavLink>
                </li>
                <li>
                    <NavLink to="/profile"><span className="icon is-small"><FaUserCircle /></span></NavLink>
                </li>
              </ul>
            </div>
          </div>
        </section> 
     
        <div className="content">
          <Route exact path="/" component={MyStamps} />      
          <Route path="/mystamps" component={MyStamps}/>
          <Route path="/profile" component={Profile}/>
        </div>
        
        </HashRouter>  
      );
    }
    else {
      return null;
    }
  }
}

const signUpConfig = {
  header: 'Sign Up',
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'email'
    },
    {
      label: 'Password (Minimum length is 6)',
      key: 'password',
      required: true,
      displayOrder: 3,
      type: 'password'
    }
  ]
};
 
const MyTheme = {
  googleSignInButton: { backgroundColor: "red", borderColor: "red" },
  button: { backgroundColor: "green", borderColor: "red" },
  signInButtonIcon: { display: "none" }
};

const greetings = ["Ahoy, matey!", "Top of the mornin’ to ya!", "What’s crackin’?", "GOOOOOD MORNING, VIETNAM!", 
                    "Howdy, howdy ,howdy!", "Hello, my name is Inigo Montoya.", "I'm Batman.", "Hello, who's there, I'm talking.", 
                    "Here's Johnny!", "You know who this is.", "Ghostbusters, whatya want?", "Yo!", "Whaddup", 
                    "Greetings and salutations!", "‘Ello, mate.", "How you doin'?", "What's cookin', good lookin'?", 
                    "Why, hello there!", "Aloha", "Hola", "Que pasa", "Bonjour", "Hallo", "Ciao", "Konnichiwa",
                    "Whazzup?", "What’s new?", "Hi, lost buddy! Long time no see!", "Namaste!", "Sup!", "What’s good in the hood?",
                    "Ogenki desu ka?", "Comment allez-vous?", "Ni hao ma?", "Como estas?", "Jal jinaess-eoyo?", 
                    "Wagwan?", "How farest thou?", "Hey bud! Mind if we catch up? I saw you like eons ago.", 
                    "How is life sailing?", "Come va?", "Como voce esta?", "Hey friend! What’s the latest buzz in your world?"
                    ]

export default withAuthenticator(Main, {theme: MyTheme, includeGreetings: true, signUpConfig, 
  authenticatorComponents: [<CustomLogin/>, <ConfirmSignIn/>, <ConfirmSignUp/>, <ForgotPassword/>, 
  <RequireNewPassword/>, <SignUp signUpConfig={signUpConfig}/>, <VerifyContact/>, 
  <Greetings inGreeting={(username) => greetings[Math.floor(Math.random() * greetings.length)]}/>
]});

