/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateVideotimestamp = /* GraphQL */ `
  subscription OnCreateVideotimestamp($owner: String!) {
    onCreateVideotimestamp(owner: $owner) {
      id
      username
      videourl
      title
      description
      tags
      playlist
      createdAt
      owner
    }
  }
`;
export const onUpdateVideotimestamp = /* GraphQL */ `
  subscription OnUpdateVideotimestamp($owner: String!) {
    onUpdateVideotimestamp(owner: $owner) {
      id
      username
      videourl
      title
      description
      tags
      playlist
      createdAt
      owner
    }
  }
`;
export const onDeleteVideotimestamp = /* GraphQL */ `
  subscription OnDeleteVideotimestamp($owner: String!) {
    onDeleteVideotimestamp(owner: $owner) {
      id
      username
      videourl
      title
      description
      tags
      playlist
      createdAt
      owner
    }
  }
`;
