import React, { Component } from "react";
import { Auth } from 'aws-amplify';

 
class Profile extends Component {

constructor() {
    super();
    this.state = {
      fullname: '',
      email: '',
      bio: '',
      website: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.updateData = this.updateData.bind(this);

  }

handleChange = e => this.setState({ [e.target.name]: e.target.value })

updateData(){

  if(this.state.username !== this.state.i_username || this.state.fullname !== this.state.i_fullname || 
    this.state.email !== this.state.i_email || this.state.bio !== this.state.i_bio || 
    this.state.website !== this.state.i_website){
    let userattr = {
                    'custom:preferredusername': this.state.username ? this.state.username : '',
                    'custom:fullname': this.state.fullname ? this.state.fullname : '', 
                    'custom:bio': this.state.bio ? this.state.bio : '', 
                    'custom:website': this.state.website ? this.state.website : ''
                    } 
    Auth.currentAuthenticatedUser()
    .then(user => 
      Auth.updateUserAttributes(user, userattr
    ))

    this.setState({ updated: true, message: 'Profile updated!' })
  }
  else{
    this.setState({ updated: false, message: 'No changes were made.' })
  }
  setTimeout(() => this.setState({message:''}), 3000);
}

componentDidMount(){
  Auth.currentAuthenticatedUser({bypassCache: true}).then((userInfo) => {
    const { attributes = {} } = userInfo;
    //console.log(userInfo);
    this.setState({
      //username: userInfo.username,
      username: attributes['custom:preferredusername'],
      email: attributes['email'],
      fullname: attributes['custom:fullname'],
      bio: attributes['custom:bio'],
      website: attributes['custom:website'],
      message: '',
      i_username: attributes['custom:preferredusername'],
      i_email: attributes['email'],
      i_fullname: attributes['custom:fullname'],
      i_bio: attributes['custom:bio'],
      i_website: attributes['custom:website']
    })
  })
}

render() {
  return (
  <>
  
  <section className="section">
    <div className="container">
      
      <div className="field">
        <label className="label">Username
        <div className="control">
          <input className="input" type="text" name="username" value={this.state.username || ''} onChange={this.handleChange} />
        </div>
        </label>
      </div>

      <div className="field">
        <label className="label">Name
        <div className="control">
          <input className="input" type="text" name="fullname" value={this.state.fullname || ''} onChange={this.handleChange}/>
        </div>
        </label>
      </div>

      <div className="field">
        <label className="label">Email
        <div className="control">
          <input className="input" type="email" name="email" value={this.state.email || ''} onChange={this.handleChange}/>
        </div>
        </label>
      </div>

      <div className="field">
        <label className="label">Bio
        <div className="control">
          <input className="input" type="text" name="bio" value={this.state.bio || ''} onChange={this.handleChange}/>
        </div>
        </label>
      </div>

      <div className="field">
        <label className="label">Website
        <div className="control">
          <input className="input" type="website" name="website" value={this.state.website || ''} onChange={this.handleChange}/>
        </div>
        </label>
      </div>

      <div style={{marginTop: '10px', marginBottom: '10px'}}><b>{this.state.message}</b></div>


      <div className="field">
        <p className="control">
          <input className="button is-link" type="submit" value="Update" onClick={this.updateData}/>
        </p>
      </div>
    </div>
  </section>  

  </>  
    );
  }
}
 
export default Profile;