import React, { useEffect, useReducer } from 'react';

import API, { graphqlOperation} from '@aws-amplify/api';

import {Auth} from 'aws-amplify';
import PubSub from '@aws-amplify/pubsub';

import { getTimestampsByDate } from '../../graphql/queries';
import { onCreateVideotimestamp, onUpdateVideotimestamp } from '../../graphql/subscriptions';
import { searchVideotimestamps } from '../graphqlOps'

import awsconfig from '../../config';

API.configure(awsconfig);
PubSub.configure(awsconfig);

// Action Types
const USERINFO = 'USERINFO';
const QUERY = 'QUERY';
const SUBSCRIPTION = 'SUBSCRIPTION';
const ONUPDATE = 'ONUPDATE';
const SETTAG = 'SETTAG'
const SEARCHRESULTS = 'SEARCHRESULTS'
const NOMATCHES = 'NOMATCHES'
const NOMATCHES_ERROR = 'NOMATCHES_ERROR'

const initialState = {
    videotimestamps: [],
    id: '',  username: '', videourl: '', title : '', description: '', tags: '', 
    edit: false, editingUsername: '', editingVideo: '', editingDescription: '',
    editingTags: '', editingTitle: '',
    search: '', displaySearch: false,
    deleteModal: false,
    showOptions: false, optionsId: '',
    showLoadMoreButton: false,
    nextToken: '',
    foundMessage: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case USERINFO: 
      return {...state, username: action.username};
    case QUERY:
      return {...state, videotimestamps:[...state.videotimestamps, ...action.payload.videotimestamps], nextToken: action.payload.nextToken, foundMessage: action.payload.videotimestamps.length > 0 ? "" : "No timestamps found."};
    case SUBSCRIPTION:
      return {...state, videotimestamps:[action.videotimestamp, ...state.videotimestamps]};
    case ONUPDATE:
      return {...state, 
        videotimestamps: state.videotimestamps.map(x => { if(x.username === action.videotimestamp.username && x.id === action.videotimestamp.id) 
              {
                x.description = action.videotimestamp.description
                x.tags = action.videotimestamp.tags
              }
              return x
            })};
    case SETTAG: 
      return {...state, search: action.tag}
    case SEARCHRESULTS:
      return {...state, searchResults: action.searchResults, displaySearch:true}
    case NOMATCHES:
      return {...state, searchMessage: 'No Matches'}
    case NOMATCHES_ERROR:
      return {...state, searchMessage: 'No Matches', displaySearch:true}
    default:
      return state;
  }
};

 

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function getData() {
            const stampsData = await API.graphql(graphqlOperation(getTimestampsByDate, {username: state.username, sortDirection: 'DESC', limit: 5, nextToken: state.nextToken ? state.nextToken : null}));
            dispatch({ type: QUERY, payload:
              {videotimestamps: stampsData.data.getTimestampsByDate.items, 
                nextToken: stampsData.data.getTimestampsByDate.nextToken} });
          }

  useEffect(() => {
    async function getUser(){
      await Auth.currentUserPoolUser().then((userInfo) => {
        if(userInfo){
            const { username } = userInfo;
            dispatch({ type: USERINFO, username: username });
          }
      })
    }
    getUser()

  }, [])  

    useEffect(() => {
      if(state.username)
      {  
        let subscription, updateSubscription

        getData();
      
        async function getSubscription() {
         subscription = await API.graphql(graphqlOperation(onCreateVideotimestamp, {owner: state.username})).subscribe({
            next: (eventData) => {
              const videotimestamp = eventData.value.data.onCreateVideotimestamp;
              dispatch({ type: SUBSCRIPTION, videotimestamp });
            }
          });
      }

      getSubscription()

      async function getUpdate() {
         updateSubscription = await API.graphql(graphqlOperation(onUpdateVideotimestamp, {owner: state.username})).subscribe({
            next: (eventData) => {
              const videotimestamp = eventData.value.data.onUpdateVideotimestamp;
              dispatch({ type: ONUPDATE, videotimestamp });
            }
          }); 
      }      

      getUpdate()   

      return () => {subscription.unsubscribe(); updateSubscription.unsubscribe()};
      }
    }, [state.username]); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSearch(event, tag) {
    event.preventDefault();
    event.stopPropagation();

    if(tag){
      //setState({search: tag});
      dispatch({type: SETTAG, tag: tag})
    }

    let result = null;

    try {
      result = await API.graphql(graphqlOperation(searchVideotimestamps, state.search));
      
      //setState({searchResults:result.data.searchVideotimestamps.items, displaySearch:true, /*search:''*/});   
      dispatch({type: SEARCHRESULTS, searchResults:result.data.searchVideotimestamps.items})

      if(JSON.stringify(result.data.searchVideotimestamps.items) === '[]'){
        //setState({searchMessage: 'No Matches'});
        dispatch({type: NOMATCHES})
      };
    }
    catch(err){
      console.log(err)
      //setState({searchMessage: 'No Matches', displaySearch:true, /*search:''*/});  
      dispatch({type: NOMATCHES_ERROR})
    }

  }

  return (
    <>
    <section className="section">
      <div className="container">
        <div className="content is-vcentered has-text-centered"> 

        {Array.isArray(state.videotimestamps) && state.videotimestamps.length ? state.videotimestamps.filter(x => x._deleted !== true).map((t, i) => 

        <div className="card has-margin-bottom-20" style={{background: t.videourl === state.url ? "#FF8000" : "white"}} key={"card-" + i}>

          <div className="card-image">

            <figure className="image is-128x128 is-inline-block">
              
              <img className="has-margin-top-25" src={`https://img.youtube.com/vi/${t.videourl}/default.jpg`} alt={t.title}/>
              
            </figure>
          </div>
          <div className="card-content">
            <div className="has-text-centered">
              <p className="title is-5">{t.title}</p>
            </div>

            <div className="content has-margin-top-10 has-text-centered">
              {   
                  t.description && t.description.split(';;').map( (x, i) =>
                          x && <React.Fragment key={i}><a href={`https://www.youtube.com/watch?v=${t.videourl}&t=${x.split('::')[0].split(':')[0]}h${x.split(' ')[0].split(':')[1]}m${x.split(' ')[0].split(':')[2]}s`} target="_blank" rel="noopener noreferrer">
                         {x.replace("::", " ")}</a>{" "}<br /></React.Fragment>)
              }
              <br/>
              { 
                  t.tags && t.tags.split(',').map( (x, i) =>
                      x && <span className="tag" style={{marginRight: '10px'}} key={i}><a href="/#" onClick={(e) => {handleSearch(e, x)}}>#{x}</a>{" "}</span>
                  )
              }
              <br/>

            </div>
          </div>
        </div>

        ): <div>{state.foundMessage}</div>
        }   

        { state.nextToken ?
          <div className="content is-vcentered has-text-centered" style={{marginTop: '20px'}}>
            <a href="/#" className="button is-normal" onClick={() => getData()}>Load more</a>
          </div>
          : null
        }

      </div>    
     </div>
    </section>
  </>
  );
}

export default App;